import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { errorToast, successToast } from "./toast";

const useLogout = (silent = false) => {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const logout = (msg) => {
    setToken(null);
    if (!silent) {
      if (msg) {
        errorToast(msg || "Something went wrong, logging out!");
      }
      successToast("Logout successfully!");
      localStorage.setItem("current_url", location.pathname);
      navigate("/", { replace: true });
    }
  };

  return { logout };
};

export default useLogout;
