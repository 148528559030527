import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import Loading from "../../components/Loading";
import { usePost } from "../../utils/hooks";
import { errorToast, successToast } from "../../utils/toast";
import { useAuth } from "../../utils/AuthProvider";

const Login = () => {
  const [creds, setCreds] = useState({ email: "", password: "" });

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const { makeRequest, isLoading } = usePost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate/login`,
    (data) => {
      if (data.success) {
        setToken(data.token);
        successToast("Successfully logged in!");
        const url = localStorage.getItem("current_url");
        if (url) {
          localStorage.removeItem("current_url");
          navigate(url, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      } else {
        errorToast(
          data.error.message ||
            data.error ||
            "Something went wrong, try after some time!"
        );
      }
    }
  );

  // axios post request
  const handleSubmit = async (e) => {
    e.preventDefault();
    makeRequest(document.querySelector("#my-form"));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value });
  };

  return (
    <div className="mx-auto max-w-screen-md">
      {isLoading && <Loading />}
      <Card
        shadow={false}
        className="md:px-24 md:py-14 py-8 border border-gray-300"
      >
        <CardHeader shadow={false} floated={false} className="text-center">
          <h1 className="mb-4 !text-3xl lg:text-4xl">Login Here</h1>
          <p className="!text-gray-600 text-[18px] m-auto font-normal md:max-w-sm">
            Enjoy quick and secure access to your accounts.
          </p>
        </CardHeader>
        <CardBody>
          <form
            id="my-form"
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 md:mt-6"
          >
            <Input
              label="Your Email"
              id="email"
              size="lg"
              type="email"
              name="email"
              placeholder="name@mail.com"
              value={creds.email}
              onChange={onChange}
              required
            />
            <Input
              label="Password"
              id="password"
              size="lg"
              type="password"
              name="password"
              placeholder="********"
              value={creds.password}
              onChange={onChange}
              required
            />
            <Button type="submit" size="lg" className="bg-primary" fullWidth>
              Login
            </Button>
            <Typography
              variant="small"
              className="text-center mx-auto max-w-[19rem] !font-medium !text-gray-600"
            >
              {"Not a member? "}
              <Link to="/registration" className="text-gray-900">
                Sign-up now
              </Link>
            </Typography>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;
