import {
  Card,
  Typography,
  Button,
  CardBody,
  CardFooter,
  CardHeader,
} from "@material-tailwind/react";
import paginateArray from "paginate-array";
import { useCallback, useMemo, useState } from "react";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import NoDataFound from "../../components/NoDataFound";
import { isEmpty } from "lodash";

const TABLE_HEAD = ["आयोजन दिनांक", "स्थळ", "माहिती"];

const usePaginated = (data, numItemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const paginateCollection = paginateArray(data, currentPage, numItemsPerPage);
  const next = useCallback(() => {
    const nextPage = currentPage + 1;
    if (nextPage > paginateCollection.totalPages) {
      return;
    }
    setCurrentPage(nextPage);
  }, [currentPage, paginateCollection.totalPages]);

  const prev = useCallback(() => {
    const prevPage = currentPage - 1;
    if (prevPage < 1) {
      return;
    }
    setCurrentPage(prevPage);
  }, [currentPage]);

  return useMemo(
    () => ({
      ...paginateCollection,
      next,
      prev,
    }),
    [next, paginateCollection, prev]
  );
};

export function ListOfMelawas({ list }) {
  const { currentPage, totalPages, data, next, prev } = usePaginated(list, 3);

  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" className="text-primary">
              Previous Melawas (मागील मेळावे)
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Previous Melawas Information
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-x-scroll md:overflow-x-auto px-0">
        <table className="w-full min-w-max md:table-fixed text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-bold leading-none"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map(({ place, information, date }, index) => {
              const isLast = index === data.length - 1;
              const classes = isLast
                ? "p-4 text-wrap max-w-72"
                : "p-4 border-b border-blue-gray-50 text-wrap max-w-72";

              return (
                <tr key={index}>
                  <td className={`max-w-64 ${classes}`}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {date}
                    </Typography>
                  </td>
                  <td className={`max-w-64 ${classes}`}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {place}
                    </Typography>
                  </td>
                  <td className={`max-w-96 ${classes}`}>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {information}
                    </Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {currentPage} of {totalPages}
        </Typography>
        <div className="flex gap-2">
          <Button variant="outlined" size="sm" onClick={prev}>
            Previous
          </Button>
          <Button variant="outlined" size="sm" onClick={next}>
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

const PreviousMelawas = () => {
  const { data, isLoading } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/previous_melawas`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  return (
    <>
      <ListOfMelawas list={data} />
    </>
  );
};

export default PreviousMelawas;
