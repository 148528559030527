import React from "react";
import { Avatar, Card, CardBody, CardHeader } from "@material-tailwind/react";
import { useAuthFetch, useFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import PaymentButton from "../../components/PaymentButton";

const PaymentCard = ({ user }) => {
  const { data: rpKey, isLoading } = useFetch("/rp.json");

  return (
    <div className="mx-auto max-w-screen-lg py-12">
      {isLoading && <Loading />}
      <Card
        shadow={false}
        className="md:px-24 md:py-14 py-8 border border-gray-300"
      >
        <CardHeader shadow={false} floated={false} className="text-center">
          <div className="flex flex-col justify-center items-center">
            <Avatar src={user.image} alt="avatar" className="w-32 h-32" />
          </div>
          <h1 className="mb-2 text-4xl" style={{ lineHeight: "3.5rem" }}>
            {`Welcome ${user.name},`}
          </h1>
        </CardHeader>
        <CardBody>
          <form id="my-form" className="flex flex-col gap-4 md:mt-2">
            <p className="!text-gray-600 text-[18px] m-auto font-normal md:max-w-md"></p>
            <p className="text-red-500 text-[24px] text-center m-auto font-bold md:max-w-lg">
              {"तुमची नोंदणी पूर्ण करण्यासाठी, कृपया पेमेंट पूर्ण करा."}
            </p>
          </form>
          <PaymentButton user={user} rpKey={rpKey} />
        </CardBody>
      </Card>
    </div>
  );
};

const Payment = () => {
  const navigate = useNavigate();
  let { isLoading, data } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate`
  );

  const { isLoading: paymentLoading, data: paymentStatus } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/razorpay_payment_logs`
  );

  if (isLoading || paymentLoading) {
    return <Loading />;
  }

  if (!data) {
    return;
  }

  if (
    paymentStatus?.[0]?.log &&
    JSON.parse(paymentStatus?.[0]?.log)?.razorpay_payment_id
  ) {
    navigate("/");
    return;
  }

  const { id, password, updated_at, created_at, ...user } = data;

  return <PaymentCard user={user} />;
};

export default Payment;
