import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import ScrollToTop from "../components/ScrollToTop";

const AuthenticatedMain = () => (
  <>
    <Header />
    <main id="main" className="flex-1 bg-secondary bg-opacity-20">
      <div className="mx-auto flex-1 max-w-screen-xl py-12">
        <Outlet />
      </div>
    </main>
    <Footer />
    <ScrollToTop />
  </>
);

export default AuthenticatedMain;
