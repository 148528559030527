import { useAuth } from "../../utils/AuthProvider";

const useGenerateLinks = () => {
  const { token } = useAuth();

  return [
    {
      title: "Quick Links",
      items: [
        {
          title: "Home",
          link: "/",
        },
        ...(token
          ? []
          : [
              {
                title: "Groom / Bride Login",
                link: "/login",
              },
              {
                title: "Member Login",
                link: "/admin",
              },
            ]),
      ],
    },
    {
      title: "More about us",
      items: [
        {
          title: "Committee Information",
          link: "/committee-information",
        },
        {
          title: "Membership Policies",
          link: "/membership-policies",
        },
        {
          title: "Previous Melawas",
          link: "/previous-melawas",
        },
      ],
    },
  ];
};

export default useGenerateLinks;
