import { Link } from "react-router-dom";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../Loading";
import AlertBox from "./AlertBox";
import useMarkedPaymentStatus from "./useMarkedPaymentStatus";

const PaymentStatus = () => {
  const { isLoading, data, refetch } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/razorpay_payment_logs`
  );

  useMarkedPaymentStatus(refetch);

  if (isLoading || !data) {
    return <Loading />;
  }

  return (
    <>
      {data?.[0]?.log ? (
        JSON.parse(data?.[0]?.log)?.razorpay_payment_id ? (
          <AlertBox
            color="green"
            title="Success"
            message={`Payment was successful with the payment id - ${
              data?.[0]?.log && JSON.parse(data?.[0]?.log)?.razorpay_payment_id
            }.`}
          />
        ) : (
          <AlertBox
            color="red"
            title="Alert"
            message={
              <>
                {
                  "Payment was not successful due some failure. Please reach out to the contact person for more information."
                }
                <br />
                {data?.[0]?.log}
              </>
            }
          />
        )
      ) : (
        <AlertBox
          color="red"
          title="Alert"
          message={
            <>
              {
                "Payment was not successful due some failure. Please reach out to the contact person for more information."
              }
              <br />
              <Link to="/payment" className="font-bold underline">
                Re-try payment
              </Link>
            </>
          }
        />
      )}
    </>
  );
};

export default PaymentStatus;
