import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "../pages/ProtectedRoute";
import { useAuth } from "./AuthProvider";
import Dashboard from "../pages/candidate/Dashboard";
import Profile from "../pages/candidate/Profile";
import HomePage from "../pages/public/HomePage";
import Login from "../pages/candidate/Login";
import Registration from "../pages/candidate/Registration";
import AdminLogin from "../pages/admin/AdminLogin";
import Payment from "../pages/candidate/Payment";
import CommitteeInformation from "../pages/public/CommitteeInformation";
import MembershipPolicies from "../pages/public/MembershipPolicies";
import PreviousMelawas from "../pages/public/PreviousMelawas";
import ImageGallery from "../pages/public/ImageGallery";
import Main from "../layout/Main";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      element: <Main />,
      children: [
        {
          path: "/committee-information",
          element: <CommitteeInformation />,
        },
        {
          path: "/membership-policies",
          element: <MembershipPolicies />,
        },
        {
          path: "/previous-melawas",
          element: <PreviousMelawas />,
        },
        {
          path: "/gallery",
          element: <ImageGallery />,
        },
      ],
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/payment",
          element: <Payment />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [
    {
      path: "/",
      element: <Main />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/committee-information",
          element: <CommitteeInformation />,
        },
        {
          path: "/membership-policies",
          element: <MembershipPolicies />,
        },
        {
          path: "/previous-melawas",
          element: <PreviousMelawas />,
        },
        {
          path: "/gallery",
          element: <ImageGallery />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/registration",
          element: <Registration />,
        },
        {
          path: "/admin",
          element: <AdminLogin />,
        },
      ],
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
