import React from "react";
import PaymentStatus from "../../components/PaymentStatus";

const Dashboard = () => {
  return (
    <>
      <PaymentStatus />
    </>
  );
};

export default Dashboard;
