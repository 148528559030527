import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import PhoneIcon from "@mui/icons-material/Phone";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import NoDataFound from "../../components/NoDataFound";
import { isEmpty } from "lodash";

export function ProfileCard({ url, name, designation, contact }) {
  return (
    <div className="p-2">
      <Card className="w-full">
        <CardHeader floated={false}>
          <img src={url} alt="profile-picture" className="w-48 h-52"/>
        </CardHeader>
        <CardBody className="text-center p-4">
          <Typography variant="h6" color="blue-gray" className="mb-2 text-xs">
            {name}
          </Typography>
          <Typography
            color="blue-gray"
            className="text-xs md:text-md font-bold"
            textGradient
          >
            {designation}
          </Typography>
        </CardBody>
        <CardFooter className="flex justify-center gap-7 pt-0 p-4">
          <a
            href={`tel:${contact}`}
            className="w-full"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              size="lg"
              className="text-primary justify-center flex items-center px-1.5"
              fullWidth={true}
            >
              <PhoneIcon className="stroke-primary" />
              {contact}
            </Button>
          </a>
        </CardFooter>
      </Card>
    </div>
  );
}

const CommitteeInformation = () => {
  const { data, isLoading } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/committees`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  return (
    <>
      <Typography
        variant="h4"
        className="text-lg md:text-2xl mb-2 text-primary text-center"
      >
        {"Committee Information (महासंघ कार्यकारणी)"}
      </Typography>
      <div className="flex items-center justify-center flex-wrap">
        {data.map((d) => (
          <ProfileCard {...d} />
        ))}
      </div>
    </>
  );
};

export default CommitteeInformation;
