import { Card, CardBody, CardHeader } from "@material-tailwind/react";
import React from "react";

const PresidentCard = ({ president: { profile_url, tellUs, name, title } }) => (
  <Card className="w-full md:h-full max-w-[48rem] flex-col items-center md:flex-row">
    <CardHeader
      shadow={false}
      floated={false}
      className="m-0 w-2/5 shrink-0 rounded-xl flex justify-center items-center"
    >
      <img
        src={profile_url}
        alt="card-image"
        className="object-cover mt-4 md:mt-0"
      />
    </CardHeader>
    <CardBody>
      <h6 className="mb-4 text-md">{tellUs}</h6>
      <h4 className="mb-2 text-lg md:text-xl">{name}</h4>
      <p className="md:mb-8 font-normal text-md md:text-lg">{title}</p>
    </CardBody>
  </Card>
);

export default PresidentCard;
