import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthPost } from "../../utils/hooks";
import { successToast } from "../../utils/toast";
import { useEffect, useRef } from "react";
import errorAlert from "../../utils/errorAlert";

const useMarkedPaymentStatus = (onSuccess) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const paymentLoadingRef = useRef(false);

  const { makeRequest } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/razorpay_payment_logs`,
    (data) => {
      paymentLoadingRef.current = false;
      if (data.success) {
        successToast("Payment completed successful!");
        navigate("/", { replace: true });
        onSuccess?.();
      } else {
        errorAlert(data);
      }
    }
  );

  useEffect(() => {
    if (!paymentLoadingRef.current && searchParams.get("payment_id")) {
      paymentLoadingRef.current = true;
      makeRequest({
        log: JSON.stringify({
          razorpay_payment_id: searchParams.get("payment_id"),
        }),
      });
    }
  }, [location.pathname, makeRequest, navigate, searchParams]);
};

export default useMarkedPaymentStatus;
