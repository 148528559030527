import { List, ListItem, Typography } from "@material-tailwind/react";
import { useAuthFetch } from "../../utils/hooks";
import Loading from "../../components/Loading";
import NoDataFound from "../../components/NoDataFound";
import { isEmpty } from "lodash";

const MembershipPolicies = () => {
  const { data, isLoading } = useAuthFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/policies`
  );

  if (isLoading || !data) {
    return <Loading />;
  }

  if (isEmpty(data)) {
    return <NoDataFound />;
  }

  return (
    <>
      <Typography variant="h4" className="mb-2 text-primary">
        {"Policies for Membership (सदस्यत्वासाठी धोरणे)"}
      </Typography>
      <Typography variant="h5" className="font-medium">
        {"महासंघाचे सदस्य होण्याकरिता नियम व अटी:-"}
      </Typography>
      <List>
        {data.map((rule, rIndex) => (
          <ListItem key={rIndex} className="font-medium">
            {`${rIndex + 1}. ${rule.name}`}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default MembershipPolicies;
