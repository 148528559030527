import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useFormConfigurations,
  renderInput,
  userSchemaWithoutPassword,
} from "./Registration";
import Loading from "../../components/Loading";
import { useAuthPost, useAuthFetch } from "../../utils/hooks";
import {  successToast } from "../../utils/toast";
import { isEmpty } from "lodash";
import errorAlert from "../../utils/errorAlert";

const ProfileBody = ({ user, refetch }) => {
  const { makeRequest, isLoading } = useAuthPost(
    `${process.env.REACT_APP_API_BASE_URI}/api/candidate/update`,
    (data) => {
      if (data.success) {
        successToast("Profile updated successfully!");
        refetch?.();
      } else {
        errorAlert(data);
      }
    }
  );

  const formData = useForm({
    defaultValues: user,
    resolver: yupResolver(userSchemaWithoutPassword),
  });

  const formConfigurations = useFormConfigurations(true);

  return (
    <>
      {isLoading && <Loading />}
      <div className="mx-auto max-w-screen-lg py-12">
        <Card
          shadow={false}
          className="md:px-24 md:py-14 py-8 border border-gray-300"
        >
          <CardHeader shadow={false} floated={false} className="text-center">
            <h1 className="mb-2 text-4xl" style={{ lineHeight: "3.5rem" }}>
              {"Profile (प्रोफाइल)"}
            </h1>
            <p className="!text-gray-600 text-[18px] m-auto font-normal md:max-w-sm">
              {"Groom and Bride Information Form"}
            </p>
            <p className="!text-gray-600 text-[18px] m-auto font-normal md:max-w-sm">
              {"उपवर उपवधूंच्या माहितीसाठी चा फॉर्म"}
            </p>
          </CardHeader>
          <CardBody>
            <form
              onSubmit={formData.handleSubmit(makeRequest)}
              id="my-form"
              className="flex flex-col gap-4 md:mt-2"
            >
              {formConfigurations.sections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-4 font-medium "
                  >
                    {section.title}
                  </Typography>
                  {section.inputGroups.map((inputGroup, igIndex) => (
                    <div
                      key={igIndex}
                      className={`grid md:grid-cols-${inputGroup.length} gap-2`}
                    >
                      {inputGroup.map((inputConfig, icIndex) => (
                        <React.Fragment key={icIndex}>
                          {renderInput(formData, inputConfig)}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
              <div>
                <Button
                  type="submit"
                  size="lg"
                  className="bg-primary"
                  fullWidth
                >
                  {"Update (अद्यतन)"}
                </Button>
                {!isEmpty(formData?.formState?.errors) && (
                  <p className="flex items-center justify-center gap-1 font-normal text-md text-red-500">
                    {
                      "To Update, Please resolve the all validation errors by providing valid inputs"
                    }
                  </p>
                )}
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

const Profile = () => {
  const { isLoading, data, refetch } = useAuthFetch(`${process.env.REACT_APP_API_BASE_URI}/api/candidate`);

  if (isLoading || !data) {
    return <Loading />;
  }

  const { id, password, updated_at, created_at, ...user } = data;

  return <ProfileBody id={id} user={user} refetch={refetch} />;
};

export default Profile;
