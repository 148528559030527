const Logo = () => (
  <div className="flex items-center justify-center">
    <img
      style={{ width: 40, height: 40 }}
      src={require("../../assets/logo.webp")}
      alt="Card cap"
    />
    <span className="px-4 font-bold">{"सुतार (झाडे) समाज महासंघ"}</span>
    <img
      style={{ width: 40, height: 40 }}
      src={require("../../assets/Sutar_Zade_Samaj_Logo.webp")}
      alt="Card cap"
    />
  </div>
);

export default Logo;
